class proxyInit {
  constructor(cb) {
    this.activated = false;
    this.cb = cb;
  }

  init() {
    if (this.activated) return;
    else {
      this.activated = true;
      this.cb();
    }
  }
}

const activate = (cb) => {
  cb = new proxyInit(cb);

  const el = document.body;

  // init dev
  if (process.env.NODE_ENV && process.env.NODE_ENV === "development") {
    cb.init();
  }

  let spaces = 0;

  // init desktop
  const keyUp = function(e) {
    if (e.keyCode == 32 && spaces++ < 2) {
      if (spaces >= 2) {
        el.removeEventListener("keyup", keyUp);
        cb.init();
      }
      setTimeout(() => {
        spaces--;
      }, 1000);
    }
  };
  el.addEventListener("keyup", keyUp);

  // init mobile
  let touchStartY, touchEndY;
  const touchStart = (evt) => {
      touchStartY = touchEndY = evt.touches[0].clientY;
    },
    touchMove = (evt) => {
      touchEndY = evt.touches[0].clientY;
    },
    touchEnd = () => {
      const windowHeight = document.body.clientHeight;
      const distance = touchStartY - touchEndY;
      if (touchStartY > windowHeight - windowHeight / 4) {
        if (distance > windowHeight / 3) {
          el.removeEventListener("touchstart", touchStart);
          el.removeEventListener("touchmove", touchMove);
          el.removeEventListener("touchend", touchEnd);
          cb.init();
        }
      }
    };

  el.addEventListener("touchstart", touchStart, false);
  el.addEventListener("touchmove", touchMove, false);
  el.addEventListener("touchend", touchEnd, false);
};

export default activate;
