import { createApp } from "vue";
import App from "./App.vue";
import installElementPlus from "./plugins/element";
import CKEditor from "@ckeditor/ckeditor5-vue";
import initApp from "@/helpers/initApp";

initApp(() => {
  document.querySelector(".demo-1").remove();
  createApp(App)
    .use(installElementPlus)
    .use(CKEditor)
    .mount("#app");
});
