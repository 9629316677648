<template>
  <el-form :model="form" :rules="rules" ref="form" label-position="top">
    <el-form-item label="Заголовок" prop="title">
      <el-input v-model="form.title" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="Контент" prop="content">
      <ckeditor
        :editor="editor"
        :config="editorConfig"
        v-model="form.content"
      ></ckeditor>
    </el-form-item>
    <el-form-item label="Коллекции" prop="category">
      <el-select
        v-model="form.category"
        multiple
        filterable
        default-first-option
        clearable
        allow-create
        placeholder="Выберите или добавьте новый"
        style="width: 100%"
      >
        <el-option
          v-for="item in getFacetValues('category')"
          :key="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Теги" prop="keywords">
      <el-select
        v-model="form.keywords"
        multiple
        filterable
        default-first-option
        clearable
        allow-create
        placeholder="Выберите или добавьте новый"
        style="width: 100%"
      >
        <el-option
          v-for="item in getFacetValues('keywords')"
          :key="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-input type="hidden" v-model="form.objectID"></el-input>
    <el-space>
      <el-button type="primary" @click="submitForm" :loading="loading"
        >Сохранить</el-button
      >
    </el-space>
  </el-form>
</template>

<script>
import algolia from "@/api/algolia";
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import AutoImagePlugin from "@ckeditor/ckeditor5-image/src/autoimage.js";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image.js";
import ImageInsertPlugin from "@ckeditor/ckeditor5-image/src/imageinsert.js";
import ImageResizePlugin from "@ckeditor/ckeditor5-image/src/imageresize.js";
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle.js";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
import ImageUploadPlugin from "@ckeditor/ckeditor5-image/src/imageupload.js";
import ImageLinkPlugin from "@ckeditor/ckeditor5-link/src/linkimage";
import AutoformatPlugin from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
import BlockQuotePlugin from "@ckeditor/ckeditor5-block-quote/src/blockquote.js";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import AlignmentPlugin from "@ckeditor/ckeditor5-alignment/src/alignment.js";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import CodeBlockPlugin from "@ckeditor/ckeditor5-code-block/src/codeblock.js";
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading.js";
import ListPlugin from "@ckeditor/ckeditor5-list/src/list.js";
import MediaEmbedPlugin from "@ckeditor/ckeditor5-media-embed/src/mediaembed.js";
import PasteFromOfficePlugin from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import RemoveFormatPlugin from "@ckeditor/ckeditor5-remove-format/src/removeformat.js";
import TablePlugin from "@ckeditor/ckeditor5-table/src/table.js";
import TableToolbarPlugin from "@ckeditor/ckeditor5-table/src/tabletoolbar.js";
import TextTransformationPlugin from "@ckeditor/ckeditor5-typing/src/texttransformation.js";
import HighlightPlugin from "@ckeditor/ckeditor5-highlight/src/highlight";
import HorizontalLinePlugin from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import TodoListPlugin from "@ckeditor/ckeditor5-list/src/todolist";

const { save } = algolia();

export default {
  name: "AlgoliaForm",
  props: ["fields", "facets", "defaultCategory"],
  data() {
    const { objectID, title, content, category, keywords } = this.fields || {};
    return {
      form: {
        objectID,
        title: title || "",
        content: content || "",
        category:
          category || (this.defaultCategory ? [this.defaultCategory] : []),
        keywords: keywords || []
      },
      rules: {
        title: {
          required: true,
          trigger: "change"
        },
        category: {
          required: true,
          trigger: "change"
        }
      },
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          EssentialsPlugin,
          AlignmentPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          AutoImagePlugin,
          AutoformatPlugin,
          BlockQuotePlugin,
          CodeBlockPlugin,
          HeadingPlugin,
          TextTransformationPlugin,
          TableToolbarPlugin,
          TablePlugin,
          RemoveFormatPlugin,
          PasteFromOfficePlugin,
          MediaEmbedPlugin,
          ListPlugin,
          HighlightPlugin,
          HorizontalLinePlugin,
          TodoListPlugin,
          ImagePlugin,
          ImageInsertPlugin,
          ImageResizePlugin,
          ImageStylePlugin,
          ImageToolbarPlugin,
          ImageUploadPlugin,
          ImageLinkPlugin
        ],

        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "highlight",
            "bulletedList",
            "numberedList",
            "alignment",
            "removeFormat",
            "link",
            "blockQuote",
            "todoList",
            "horizontalLine",
            "insertTable",
            "codeBlock",
            "mediaEmbed",
            "imageUpload",
            "imageInsert"
          ]
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"]
        },
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        }
      },
      loading: false
    };
  },
  methods: {
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.saveRecord();
        } else {
          this.$message.error("Проверьте поля формы");
          return false;
        }
      });
    },
    async saveRecord() {
      this.loading = true;
      let { objectID, title, content, category, keywords } = this.form;
      try {
        const contentProccessed = await this.replaceImages(content);

        const response = await save({
          objectID,
          title,
          content: contentProccessed,
          category: Object.entries(category).map(([, v]) => v),
          keywords: Object.entries(keywords).map(([, v]) => v)
        });

        this.loading = false;
        this.$emit("onSave", response.objectID, !this.fields);
      } catch (error) {
        console.log(error);
      }
    },
    async replaceImages(content) {
      // clean content
      content = content.replace(/srcset="([^"]*)"/g, "");

      // get image urls to save
      const urls = (content.match(/<img [^>]*src="[^"]*"[^>]*>/gm) || [])
        .map((x) => x.replace(/.*src="([^"]*)".*/, "$1"))
        .filter((x) => x.indexOf("storage.googleapis.com") === -1); // skip already saved images

      // replace source urls with stored images
      if (urls.length) {
        await axios
          .post("https://api.jetspiel.com/saveFiles", { urls })
          .then(({ data }) => {
            for (const [source, stored] of Object.entries(data)) {
              content = content.replace(source, stored);
            }
          });
      }

      return content;
    },
    getFacetValues(facet) {
      return this.facets[facet].map(({ value }) => value);
    }
  }
};
</script>

<style lang="scss">
.ck-content {
  min-height: 200px;
  max-height: 400px;
}
.ck-editor__editable {
  p {
    line-height: 130%;
    margin: 5px 0;
  }
}
</style>
