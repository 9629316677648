<template>
  <el-row style="margin-bottom: 30px">
    <el-col>
      <el-input
        style="float: left; width: calc(100% - 110px)"
        placeholder="Начните вводить запрос..."
        prefix-icon="el-icon-search"
        v-model="query"
        size="medium"
        clearable
        autocomplete="off"
        autofocus
      >
      </el-input>
      <el-button
        style="float: right"
        type="warning"
        size="medium"
        @click="showModal()"
        >Добавить</el-button
      >
    </el-col>
  </el-row>
  <el-row style="margin-bottom: 30px">
    <el-col>
      <el-space v-if="facets && facets.category" :size="[20, 15]" wrap>
        <el-badge class="item">
          <el-button
            @click="filterCategory(null)"
            size="small"
            :type="category === null ? 'primary' : 'default'"
            >Все</el-button
          >
        </el-badge>
        <el-badge
          v-for="{ value, count } in facets.category"
          :key="value"
          :value="count"
          class="item"
        >
          <el-button
            @click="filterCategory(value)"
            size="small"
            :type="category === value ? 'primary' : 'default'"
          >
            {{ value }}
          </el-button>
        </el-badge>
      </el-space>
    </el-col>
  </el-row>
  <el-row v-if="searchResult">
    <el-col>
      <el-empty
        v-if="searchResult.hits.length === 0"
        description="Нет результатов"
      >
        <el-button type="primary" @click="clear">Сбросить</el-button></el-empty
      >
      <h4 v-else>{{ foundRecords }}</h4>
      <div class="search-results">
        <template v-for="hit in searchResult.hits" :key="hit.objectID">
          <div class="search-results__item" @click="showPreview(hit)">
            <div
              class="search-results__item__avatar"
              v-if="parseImage(hit.content)"
            >
              <el-avatar
                :size="120"
                shape="square"
                :src="parseImage(hit.content)"
                alt=""
              />
            </div>
            <div class="search-results__item__general">
              <el-row>
                <el-col :span="17" :lg="20">
                  <div class="search-results__item__title">
                    <span
                      v-html="
                        wrapURLs(
                          sanitize(hit._highlightResult.title.value, ['em'])
                        )
                      "
                    ></span>
                  </div>
                </el-col>
                <el-col :span="7" :lg="4">
                  <div class="search-results__item__actions">
                    <el-space :size="[10, 10]" wrap>
                      <el-button
                        type="info"
                        icon="el-icon-edit"
                        size="small"
                        circle
                        @click.stop.prevent="showModal(hit)"
                      >
                      </el-button>
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="small"
                        circle
                        @click.stop.prevent="confirmDelete(hit)"
                      >
                      </el-button>
                    </el-space>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col>
                  <div
                    class="search-results__item__content"
                    v-html="truncateContent(hit, 300)"
                  ></div>
                  <el-space
                    :size="[10, 10]"
                    wrap
                    v-if="hit.keywords.length || hit.category.length"
                    class="search-results__item__tags"
                  >
                    <el-tag
                      type="primary"
                      v-for="keyword in hit.category"
                      @click.stop="filterCategory(keyword)"
                      :key="keyword"
                      size="small"
                      effect="dark"
                    >
                      {{ keyword }}
                    </el-tag>
                    <el-tag
                      type="success"
                      v-for="keyword in hit.keywords"
                      @click.stop="
                        this.query = keyword;
                        filterCategory(null);
                      "
                      :key="keyword"
                      size="small"
                    >
                      {{ keyword }}
                    </el-tag>
                  </el-space>
                </el-col>
              </el-row>
            </div>
          </div>
        </template>
      </div>
    </el-col>
  </el-row>
  <el-dialog
    :fullscreen="isMobile"
    :title="fields ? 'Редактировать запись' : 'Добавить запись'"
    v-model="modalVisible"
    width="60%"
    destroy-on-close
  >
    <AlgoliaForm
      @onSave="onFormSave"
      :fields="fields"
      :facets="facets"
      :defaultCategory="category"
    />
  </el-dialog>
  <Teleport to="body">
    <div class="fullscreen" v-if="preview">
      <div class="fullscreen__content">
        <div class="fullscreen__content__inner">
          <div
            class="fullscreen__content__title"
            v-html="wrapURLs(sanitize(preview.title))"
          ></div>
          <div class="fullscreen__content__text">
            <div v-html="preview.content"></div>
          </div>
        </div>
      </div>
      <div class="fullscreen__close">
        <i class="el-icon el-icon-close" @click="hidePreview"></i>
      </div>
    </div>
  </Teleport>
</template>

<script>
import algolia from "@/api/algolia";
import AlgoliaForm from "./AlgoliaForm";
import trunc from "trunc-html";
import sanitizeHtml from "sanitize-html";
const { search, remove, facetValues } = algolia();

export default {
  name: "Algolia",
  components: { AlgoliaForm },
  data() {
    return {
      query: "",
      category: null,
      searchResult: null,
      modalVisible: false,
      fields: null,
      preview: null,
      facets: {},
      isMobile: window && window.innerWidth < 1000
    };
  },
  methods: {
    clear() {
      this.query = "";
    },
    showModal(row) {
      this.fields = row;
      this.modalVisible = true;
    },
    hideModal() {
      this.modalVisible = false;
    },
    onFormSave(objectID, isNew) {
      if (isNew) {
        this.query = `#${objectID}`;
      }
      this.getResults();
      this.hideModal();
      this.loadFacets();
    },
    getResults() {
      const response =
        this.query.indexOf("#") === 0 && this.query.length > 1
          ? search("", {
              filters: `objectID:${this.query.replace("#", "")}`
            })
          : search(this.query, {
              facets: "*",
              filters: this.category ? `category:"${this.category}"` : ""
            });
      response.then((result) => {
        console.log(result);
        this.searchResult = result;
      });
    },
    loadFacets() {
      facetValues("keywords").then(({ facetHits }) => {
        this.facets.keywords = facetHits;
      });
      facetValues("category").then(({ facetHits }) => {
        this.facets.category = facetHits;
      });
    },
    filterCategory(category) {
      this.category = category;
      if (this.query.indexOf("#") === 0) this.query = "";
    },
    getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
    confirmDelete({ title, objectID }) {
      this.$confirm(
        `Вы собираетесь удалить запись: <br/>"${title}"`,
        "Точно удаляем?",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Охрана, отмена",
          type: "warning",
          dangerouslyUseHTMLString: true
        }
      ).then(() => {
        remove(objectID).then(() => {
          this.getResults();
          this.loadFacets();
          this.$message("Removed");
        });
      });
    },
    parseImage(content) {
      const urls = (
        content.match(/<img [^>]*src="[^"]*"[^>]*>/gm) || []
      ).map((x) => x.replace(/.*src="([^"]*)".*/, "$1"));
      return urls.length > 0 ? urls[0] : null;
    },
    truncateContent(hit, length) {
      let content = hit.content;
      if (hit._highlightResult.content.matchedWords.length > 0) {
        content = hit._highlightResult.content.value;
      }
      content = trunc(content, length).html;
      content = sanitizeHtml(content, {
        allowedTags: ["em", "p", "ol", "ul", "li"]
      });
      return content;
    },
    sanitize(html, allowed) {
      return sanitizeHtml(html, {
        allowedTags: allowed
      });
    },
    wrapURLs(text, new_window) {
      text = text.replace(/https?:\/\/.*/i, (rest) => this.sanitize(rest, []));
      var url_pattern = /(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)(?:\.(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)*(?:\.(?:[a-z\x{00a1}\-\x{ffff}]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?/gi;
      var target = new_window === true || new_window == null ? "_blank" : "";

      return text.replace(url_pattern, (url) => {
        var protocol_pattern = /^(?:(?:https?|ftp):\/\/)/i;
        var href = protocol_pattern.test(url) ? url : "http://" + url;
        return (
          '<a class="el-link el-link--info is-underline" href="' +
          href +
          '" target="' +
          target +
          '"><span class="el-link--inner">' +
          url +
          "</span></a>"
        );
      });
    },
    showPreview(hit) {
      document.body.classList.add("is-fullscreen");
      this.preview = hit;
    },
    hidePreview() {
      this.preview = null;
      document.body.classList.remove("is-fullscreen");
    }
  },
  watch: {
    query() {
      this.getResults();
    },
    category() {
      this.getResults();
    }
  },
  mounted() {
    this.getResults();
    this.loadFacets();

    document.addEventListener("keyup", (evt) => {
      if (evt.keyCode === 27 && this.preview) {
        this.hidePreview();
      }
    });
  },
  computed: {
    foundRecords() {
      const cnt = this.searchResult ? this.searchResult.nbHits : 0;
      return `${cnt} ${this.getNoun(
        cnt,
        "результат",
        "результата",
        "результатов"
      )}`;
    }
  }
};
</script>

<style lang="scss">
.search-results {
  border-top: 1px solid #ebeef5;

  &__item {
    padding: 15px;
    cursor: default;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    background: #fff;
    transition: background-color 0.25s ease;
    &:hover {
      background: #f5f7fa;
    }
    &__avatar {
      margin-right: 20px;
    }
    &__general {
      width: 100%;
    }
    &__actions {
      text-align: right;
    }
    &__title {
      font-size: 17px;
      padding-bottom: 10px;
    }
    &__category {
      font-size: 14px;
      color: #909399;
      font-weight: 700;
      margin-top: 5px;
    }
    &__content:not(:empty) {
      font-size: 15px;
      margin-bottom: 10px;

      p {
        margin: 8px 0;
      }

      ol,
      ul {
        margin: 0;
        padding: 0 0 0 15px;
      }
    }

    &__tags {
      .el-tag {
        cursor: pointer;
      }
    }

    &__title,
    &__content {
      em {
        color: red !important;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 576px) {
  .search-results {
    &__item {
      flex-wrap: wrap;
      &__avatar {
        margin-bottom: 20px;

        > span.el-avatar {
          width: 100% !important;
          height: 160px !important;
        }
      }
    }
  }
}

.fullscreen {
  position: fixed;
  background: #fff;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;

  &__content {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    overflow: hidden;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__inner {
      padding: 30px 20px;
      margin-bottom: 40px;
    }

    &__title {
      font-size: 2.8rem;
      a {
        font-size: 1.6rem;
      }
    }

    &__text {
      font-size: 1.2rem;

      figure {
        margin: 0;
      }

      img {
        max-width: 100%;
      }

      ol,
      ul {
        padding: 0 0 0 25px;
      }
    }
  }

  &__close {
    position: fixed;
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding: 10px;
    font-size: 3rem;
  }
}
@media (max-width: 1000px) {
  .fullscreen {
    &__content {
      &__inner {
        padding: 30px 30px 10px 20px;
      }
    }

    &__close {
      top: 5px;
      right: 0px;
      font-size: 2rem;
      padding: 5px;
    }
  }
}
</style>
