<template>
  <el-container>
    <el-main>
      <Algolia />
    </el-main>
  </el-container>
</template>

<script>
import Algolia from "./components/Algolia.vue";

export default {
  name: "App",
  components: {
    Algolia
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,400;0,700;1,700&display=swap");
body {
  background: #f5f5fa;
  font-family: "Roboto Condensed", sans-serif;

  &.is-fullscreen {
    height: 100%;
    overflow: hidden;
  }
}
.el-avatar > img {
  width: 100%;
}
.el-container {
  justify-content: center;
}
.el-main {
  margin: 10vh 0;
  max-width: 1000px;
  width: 100%;
}
.el-card__header {
  font-size: 1.6rem;
}
.el-message-box {
  max-width: calc(100% - 40px);
}
</style>
