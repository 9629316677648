import { ElMessage } from "element-plus";
import algoliasearch from "algoliasearch";
import { createNullCache } from "@algolia/cache-common";

export default function algolia() {
  const client = algoliasearch(
    "RC5S7VF5GR",
    "80e6e536aef9ccd53658f98cb81c055a",
    {
      responsesCache: createNullCache()
    }
  );

  const index = client.initIndex(
    location.search.length > 2 ? location.search.substr(1) : "demo"
  );

  index.setSettings({
    maxFacetHits: 100,
    hitsPerPage: 1000
  });

  const errorHandler = (err, showText) => {
    console.log(err);
    if (showText) {
      ElMessage.error("Algolia api error: " + showText);
    }
  };

  const save = async (data) => {
    try {
      return await index
        .saveObject(data, {
          autoGenerateObjectIDIfNotExist: true
        })
        .wait();
    } catch (err) {
      errorHandler(err, "Object can't be saved");
    }
  };

  const search = async (...params) => {
    try {
      return await index.search(...params);
    } catch (err) {
      errorHandler(err, "Search is not available");
    }
  };

  const remove = async (objectID) => {
    try {
      return await index.deleteObject(objectID).wait();
    } catch (err) {
      errorHandler(err, "Delete failed");
    }
  };

  const facetValues = async (facet, query) => {
    try {
      return await index.searchForFacetValues(facet, query);
    } catch (err) {
      errorHandler(err, "Facet search failed");
    }
  };

  return { save, search, remove, facetValues };
}
